import mygroup from "./mygroup";
import allother from "./allother";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [allother, mygroup],
};

export default menuItems;
